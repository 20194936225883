form.form{
  label{
    display: block;
    color: #fff;
    font-weight: 700;
    font-size: 2em;
    text-align: left;
    line-height: 1em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    @media only screen and (min-width:$mobileBreakpoint) {
      font-size: 2.8em;
    }
  }
  .form-group{
    text-align: left;
    margin-bottom: 1.4em;
    input[type=text], input[type=email], input[type=date], input[type=number] {
      width:100%;
      border:none;
      padding:15px 30px;
    }
    textarea {
      width: 100%;
      height: 150px;
      padding: 16px 30px;
      box-sizing: border-box;
      border: 0px;
      background-color: #f8f8f8;
      resize: none;
    }
    // Selecteurs doivent rester séparés
    input::-webkit-input-placeholder {
      color: $inputPlaceholder !important;
    }
    input:-moz-placeholder {
      color: $inputPlaceholder !important;
    }
    input::-moz-placeholder {
      color: $inputPlaceholder !important;
    }
    input:-ms-input-placeholder {
      color: $inputPlaceholder !important;
    }

  }
  .form-error{
    display:none;
    margin-bottom: 1em;
    padding: 10px;
    color: $primaryColor;
    line-height: 1.2em;
    background: #ffffff;
  }

  .form-chkbx-list {
    ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }

    .flex-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      label {
        padding: 0;
        margin: 0 12px 0;
        height: auto;
        font-size: 1.05em;
        font-weight: 300;
        text-transform: none;
        cursor: pointer;

        @media screen and (min-width: 700px) {
          font-size: 1.35em;
        }
      }

      .custom-chkbx-wrapper {
        position: relative;
        cursor: pointer;

        input[type=checkbox] {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          opacity: 0;
          z-index: 5;
          cursor: pointer;

          &:checked ~ .chkbx {
            border-color: #fff;
          }

          &:checked ~ .chkbx:before {
            opacity: 1;
          }
        }

        .chkbx {
          width: 30px;
          height: 30px;
          border: 4px solid #bababa;
          background: none;
          border-radius: 8px;
          z-index: 1;
          transition: .2s ease;

          &:before {
            content: "\2713";
            display: inline-block;
            color: #fff;
            padding: 0 5px;
            font-size: 1.35em;
            line-height: 1.3;
            opacity: 0;
            transition: .2s ease;
          }
        }
      }

      &:hover {
        .chkbx {
          border-color: #fff;
        }
      }
    }
  }
}
