section#temoignages {
  position: relative;
  padding: 75px 0;

  &.section-grey {
    background: #f1f1f0;
  }

  .title-header {
    h2 {
      color: $primaryColor;
      font-size: 2em;
      margin-bottom: 0;
      text-transform: none;
    }
  }

  .temoignage-slider-wrapper {
    position: relative;
    padding: 16px 0;

    .slider-arrows {
      font-size: 1.5em;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: #FFF;
      border: none;
      outline: none;
      background: transparent;
      z-index: 6;

      &.slider-prev-btn {
        left: 0;
      }

      &.slider-next-btn {
        right: 0;
      }

      i.fa {
        color: #484848;
      }
    }

    .slider-testimonies {
      .testimony-slide {
        .slide-content {
          width: 100%;
          padding: 0 50px;

          p {
            text-align: center;
          }

          .testimony-name {
            text-align: right;

            span {
              font-size: 1.5rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
