section.section.section-gallery {
  position: relative;
  min-height: initial;

  .slider-arrows {
    font-size: 1.5em;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: #FFF;
    border: none;
    outline: none;
    background: transparent;
    z-index: 6;

    &.slider-prev-btn {
      left: 25px;

      &:before {
        left: -12px;
      }
    }

    &.slider-next-btn {
      right: 25px;

      &:before {
        left: -18px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      border-radius: 100%;
      background-color: #1f73ba;
      width: 53px;
      height: 53px;
      z-index: -1;
      top: 5px;
      transition: .2s ease;
    }

    &:hover {
      &:before {
        background: #18588e;
      }
    }
  }

  .slider-gallery {
    &.slick-dotted.slick-slider {
      margin-bottom: 0;
    }

    .slick-dots {
      bottom: 45px;

      li {
       button {
         &::before {
           color: #ebebeb;
         }
       }

        &.slick-active {
          button {
            &::before {
              color: #FFF;
            }
          }
        }
      }
    }

    .slick-slide {
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .bottom-logo {
    position: absolute;
    bottom: 25px;
    left: 30px;

    img {
      width: 50px;

      @media screen and (min-width: 767px){
        width: 125px;
      }
    }
  }

  .slider-controls-thumbs {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 767px) {
      display: flex;
    }

    .thumbs-item {
      width: 50px;
      height: 50px;
      margin: 8px;
      border: 2px solid #FFF;
      border-radius: 8px;
      box-shadow: 0 17px 10px -13px rgba(0, 0, 0, 1);
      transition: all .5s ease;
      transform: translateY(0);
      cursor: pointer;
      overflow: hidden;

      @media screen and (min-width: 900px) {
        width: 80px;
        height: 80px;
      }

      &:hover {
        transform: translateY(10px);
        border-color: $primaryColor;
      }

      img {
        max-width: 100%;
        border-radius: 8px;
      }
    }
  }
}
