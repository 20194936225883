.header {
  .menu {
    position: absolute;
    top: 5px;
    right: 65px;
    display: none;

    @media screen and (min-width: 1024px){
      display: block;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      li {
        float: left;
        margin: 0 10px;

        a {
          color: white;
          text-transform: uppercase;
          display: block;
          text-align: center;
          text-decoration: none;
          font-family: $titleFontFamily;
          font-size: 1.16em;
          letter-spacing: 0.02em;

          img {
            transition: .5s ease;
          }

          &:hover {
            color: darken($primaryColorReverse, 20%);

            img {
              opacity: .5;
            }
          }

          &.phoneCall {
            font-size: 2em;
            font-weight: 700;
            line-height: 0.9em;
            cursor: default;

            &:hover {
              color: #fff;
            }
          }
          img {
            margin: 10px 0;
          }
        }

        .button-primary {
          font-size: 1.16em;
          text-shadow: none;
          border-radius: 0;
          text-align: center;
          margin-top: 5px;
          letter-spacing: 0.02em;
          color: white;
          background-color: $primaryColor;
          border: 1px solid $primaryColor;

          &:hover {
            background-color: darken($primaryColor, 10%);
            border: 1px solid darken($primaryColor, 10%);
          }
        }
      }
    }

    .header-phone-wrapper {
      width: 100%;
      text-align: right;

      a.header-phone-link {
        color: #4e4e4e;
        font-size: 22px;
        font-weight: 800;
        line-height: .5em;
        text-decoration: none;
        margin: 0 14px;
        transition: .2s ease;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

.header.fixed {
  .menu {
    ul {
      li {
        a {
          &:hover {
          }

          &.phoneCall {
            &:hover {
            }
          }
        }
        .button-primary {
          &:hover {
            color: white;
          }
        }
        .button-folder {
          color: $primaryColor;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
