section.section.section-fmsdb {
  position: relative;
  min-height: initial;

  .center-content-wrapper {
    padding: 75px 0;

    .text-content {
      margin-bottom: 25px;
      text-align: center;

      @media screen and (min-width: 575px) {
       margin-bottom: 0;
      }

      @media screen and (min-width: 767px) {
        text-align: right;
      }

      h2 {
        text-transform: uppercase;
        color: #696969;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 3rem;

        @media screen and (min-width: 992px) {
          font-size: 4rem;
        }
      }

      h4 {
        font-size: 2em;
        margin-top: 0;

        @media screen and (min-width: 992px) {
          font-size: 2.15em;
        }
      }

      a {
        &.button-cta {
          width: 100%;

          @media screen and (min-width: 575px) {
            width: initial;
          }
        }
      }
    }

    .mosaic-wrapper {
      .mosaic-content {
        width: 100%;

        img {
          max-width: 100%;
        }
      }
    }
  }
}
