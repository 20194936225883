section.section-about {
  position: relative;
  padding: 100px 0 0 0;

  .title-header {
    h2 {
      color: $primaryColor;
      font-size: 2em;
      margin-bottom: 0;
      text-transform: none;
    }
  }

  p {
    &.owner {
      font-weight: 500;
    }
  }

  .about-text-content {
    ul {
      list-style-type: none;

      li {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4em;
      }
    }
  }
}
