.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px 50px;
  margin-bottom: 35px;

  .title-header {
    h2 {
      color: $primaryColor;
      font-size: 2em;
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.5em;
      font-weight: 500;
      margin-top: 5px;
    }

    &:after {
      bottom: -30px;
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: 0 0 30px 0;

    .address {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-top: 16px;

      .indivAddress {
        margin: 15px 25px;

        img {
          &.logoflip {
            width: 100px;
          }
        }

        p {
          font-size: 16px;

          .map-link-container {
            display: block;
            padding: 8px 0;

            a.gmpap-link {
              display: inline-block;
              text-decoration: none;
              background: $primaryColor;
              color: #FFF;
              padding: 8px 24px;
              transition: .2s ease;

              i.fa {
                margin-right: 6px;
                font-size: 15px;
              }

              &:hover {
                background: #18588e;
              }
            }
          }
        }

        span {
          &.city {
            color: black;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
          }
          &.phone {
            font-size: 18px;
            font-weight: 800;
            line-height: 0.5em;

            a {
              display: inline-block;
              color: #000;
              transition: .5s ease;
              text-decoration: none;

              &:hover {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }

    a {
      &.email-link {
        margin: 8px 0;
        display: block;
        color: #000;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  small {
    font-weight: 300;
    font-size: 9px;
  }

  @media only screen and (min-width: $mobileBreakpoint) {
    .contacts {
      .address {
        flex-direction: row;
      }
    }
  }

  .certified-img-wrapper {
    img {
      width: 200px;
      max-width: 100%;
    }
  }
}

.cta-footer {
  position: fixed;
  bottom: 0;
  z-index: 5000;
  font-weight: 400;
  width: 100%;
  text-align: center;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.7);

  @media only screen and (min-width: $mobileBreakpoint) {
    display: none;
  }

  .flex-wrapper {
    display: flex;

    .cta-item {
      flex: 1;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.phone-cta {
        background: #000;
      }

      &.main-cta {
        background-color: $ctaFooterBackground;
        color: #FFF;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.35em;
        font-weight: 600;
      }
    }
  }
}

.scroll-top {
  width: 75px;
  height: 75px;
  position: fixed;
  bottom: 35px;
  right: -20px;
  opacity: 0;
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);

  @media only screen and (min-width: $mobileBreakpoint) {
    bottom: 25px;
    right: 20px;
  }
}
.scroll-top i {
  display: inline-block;
  color: $primaryColor;
}
