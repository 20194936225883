section.section.section-features {
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;
  position: relative;
  color: #000;
  padding: 8em 0;

  h2 {
    color: $primaryColor;
    text-transform: none;
  }

  h4 {
    color: #000;
  }

  a {
    &.button-cta {
      margin-top: 50px;
    }
  }

  .renovation-wrapper {
    margin: 16px 0 16px;
  }

  #values_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    margin: .5rem 2%;

    .value {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      text-decoration: none;
      flex-basis: 22%;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 10px;
      width: 100%;
      transform: scale(1,1);
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
      margin-bottom: 40px;

      @media only screen and (min-width: $mobileBreakpoint) {
        margin-bottom: 0;
      }

      img {
        width: 65px;
        height: auto;
      }

      &:hover {
        transform: scale(1.05,1.05);
        transition: all 0.5s ease-in-out;
        transition-timing-function: cubic-bezier(3, 1, 2, 7);
      }

      h4 {
        text-transform: uppercase;
        font-weight: 600;
        margin: 8px 0 0 0;
        font-size: 1.5em;
      }
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      flex-direction: row;

      .menuBox {
        padding-top: 30%;
        flex-basis: 30%;
        margin: 10px;
      }
    }
    @media only screen and (min-width: 1200px) {
      flex-direction: row;

      .value {
      }
    }
  }

  @media only screen and (min-width: 1140px) {
    .productpict img {
      width: 100%;
    }
  }

  .flexAlign {
    .button-cta {
      width: 100%;
      margin: 0 auto;
      margin-top: 50px;

      @media only screen and (min-width: 700px) {
        width: 70%;
      }
    }
  }
}

i.fa {
  font-size: 3em;
}

.icon {
  transition: all .5s .3s ease-in-out;
  width: 75px;

  &.horiz {
    float: left;
    margin-bottom: 100px;
  }
}
