.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 55px;
  top:0;
  z-index: 1000;
  width:100%;
  padding: 20px;
  margin-top: 25px;
  transition: background-color .25s ease-in-out;

  .logo {
    text-align: center;
    img{
      &.logoflip {
        width: 125px;
      }
    }
  }

  .button-cta{
    display: none;
  }

  @media only screen and (min-width:1024px) {
    justify-content: space-between;
    height: $headerHeight;
    padding: 20px 30px;
    margin-top: 15px;

    .logo {
      float:left;
    }

    .button-cta{
      display: inherit;
      float: right;
    }
  }

  img.logomobile {
    margin: 8px 0 25px 0;
  }
}
