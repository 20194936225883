section.section.section-punch {
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_splash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 4em 0 10em 0;

  &.sdb {
    background-image: url("/assets/img/bkg_splash_sdb.jpg");
  }

  .punch-col {
    width: 100%;

    @media only screen and (min-width: 768px) {
      width: 62.6667%;
    }

    @media only screen and (min-width: 1024px) {
      width: 46%;
    }
  }

  @media only screen and (min-width: 480px) {
    @include sectionFlex();
    margin-top: -100px;
    padding: 4em 0 10em 0 !important;
  }

  h1 {
    font-weight: 800;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 3.5rem;
    line-height: 1.65;

    @media only screen and (min-width: 515px) {
      line-height: initial;
    }
  }

  h2 {
    font-size: 1.9em;
    margin-bottom: 8px;

    span {
      color: $primaryColor;
    }
  }

  h1, h2 {
    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  h1, h2, h3, h4 {
    text-align: center;

    @media only screen and (min-width: 700px) {
      text-align: left;
    }
  }

  p {
    text-align: center;
    color: #000;
    font-weight: 600;

    strong {
      font-weight: 600;
    }

    @media only screen and (min-width: 700px) {
      text-align: left;
    }

    a {
      &.phone-link {
        display: inline-block;
        padding: 16px 0;
        font-size: 1.5em;
        text-decoration: none;
        color: #000;
        transition: .5s ease;
        font-weight: 600;

        &:hover {
          color: #175385;
        }
      }
    }
  }

  .button-primary {
    display: inline-block;
    width: 100%;
    margin: 12px 0;

    @media only screen and (min-width: 700px) {
      width: 70%;
    }
  }

  .push-text-section {
    padding-top: 150px;

    &.flex-align-right {
      justify-content: flex-end;
    }
  }
}
