section#services {
  position: relative;
  padding: 5.5em 0;

  &.section-grey {
    background: #f1f1f0;
  }

  .title-header {
    h2 {
      color: $primaryColor;
      font-size: 2em;
      margin-bottom: 0;
      text-transform: none;
    }
  }

  .services-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-top: 50px;

    &:before {
      top: 0;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #f1f1f0;
      border-width: 30px;
      margin-left: -30px;
      z-index: 100;
    }

    &:after {
      bottom: 0;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #f1f1f0;
      border-width: 30px;
      margin-left: -30px;
      z-index: 100;
    }

    .service-item {
      position: relative;
      width: 100%;
      height: 300px;

      @media screen and (min-width: 767px){
        width: 50%;
        height: 440px;
      }

      .overlay, .figcaption-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .figcaption-wrapper {
        display: table;
        width: 100%;
        height: 100%;

        .inner {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          text-align: center;

          h3 {
            color: #FFF;
            text-transform: uppercase;
            font-size: 3rem;
          }

          a {
            &.more-btn {
              display: inline-block;
              background: #FFF;
              padding: 12px 24px;
              color: #000;
              font-weight: 600;
              text-transform: uppercase;
              text-decoration: none;
              transform: scale(1);
              transform-origin: center center;
              transition: all .5s ease-in-out;

              &:hover {
                transform: scale(1.05);
              }
            }
          }
        }
      }

      &.tailormade {
        background-image: url("/assets/img/services/services-sur-mesure.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .overlay {
          background: rgba(0, 0, 0, .5);
        }
      }

      &.refacing {
        background-image: url("/assets/img/services/services-refacing.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .overlay {
          background: rgba(33, 115, 189, .5);
        }
      }
    }
  }
}
