section#steps {
  position: relative;
  padding: 5.5em 0;

  &.section-grey {
    background: #f1f1f0;
  }

  .title-header {
    h2 {
      color: $primaryColor;
      font-size: 2em;
      margin-bottom: 0;
      text-transform: none;
    }
  }

  #ordered-steps {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;

    .step {
      p {
        font-size: 1.1em;
        line-height: 1.4em;
        font-weight: 500;
      }

      img {
        width: 250px;
        height: 250px;
        max-width: 100%;
        border-radius: 100%;
      }
    }
  }
}
