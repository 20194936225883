@media only screen and (min-width: 320px) {

}

@media only screen and (min-width: 480px) {

}

@media only screen and (min-width: 700px) {

}

@media only screen and (min-width: 768px) {
  h2 {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    #menuToggle {
      top: 21px;
    }
    .menu {
      display: block;
    }
  }
}

@media only screen and (min-width: 1140px) {

}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1600px) {
}

@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
