form#info_form_bref, form#info_form_long {
  display: flex;
  text-align: center;
  // Hack IE 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex: inherit;
    display: block;
  }

  .file-upload-wrapper {
    margin-top: 24px
  }

  h3 {
    color: #fff;
  }

  .form-section {
    width: 100%;
    max-width: 750px;
    padding: 0 1em;

    label {
      sup {
        color: #7b7b7b;
      }
    }

    .form-flex-wrapper {
      display: flex;
      width: 100%;

      .form-flex-item {
        flex: 1;
        padding: 8px;
      }
    }

    .form-control {
      select {
        width: 100%;
        padding: 14px;
        margin-top: 5px;
      }
    }

    .control-with-icon {
      position: relative;

      .icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 12px;

        i.fa {
          color: #a0a0a0;
          font-size: 1.5em;
        }
      }
    }

    button.button-primary {
      width: 100%;
      margin: 0 0 16px 0;

      @media only screen and (min-width: $mobileBreakpoint) {
        width: initial;
        margin: 0 8px 0 0;
      }
    }

    .form-checkbox-list {
      li {
        label {
          font-size: 1.05em;

          @media only screen and (min-width: $mobileBreakpoint) {
            font-size: 1.35em;
          }
        }
      }
    }
  }

  .button-primary {
    margin-top: 2em;
    background: none;
  }

  .page {
    // height:200px;
    //position: relative;
    transition: all 0.3s;
    //left:0;
    opacity: 1;

    &[data-slide="show"] {
      transform: translateX(0);
      opacity: 1;
    }

    &[data-slide="hideLeft"] {
      //left: -1000px;
      transform: translateX(-100vw);
      opacity: 0;
    }

    &[data-slide="hideRight"] {
      transform: translateX(100vw);
      opacity: 0;
    }
  }

  .page-confirm {
    .fa {
      color: white;
    }

    .message p {
      padding: 0 1em;
      color: white;
      font-size: 1.2em;
      line-height: 1.3em;
    }

    .success, .error {
      display: none;
    }
  }

}
